import { styled } from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  filter: drop-shadow(0 0 2px ${props => props.theme.colors.lightGrey});
  border-radius: 5px;
  width: calc(100% - 40px);
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  padding-bottom: 10px;
`;
