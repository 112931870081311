import { type Client } from '../../apiConfig';

import { components } from '../openapi';

export const CampaignsApi = (client: Client) => ({
  getCampaigns: () => {
    return client.GET('/api/ace/campaigns');
  },
  addCampaigns: ({ campaign } : {
    campaign: components['schemas']['Campaign-ace_campaign.write']
  }) => {
    return client.POST('/api/ace/campaigns', {
      body: campaign
    });
  },
  getCampaignById: ({ campaignId }: { campaignId:string }) => {
    return client.GET('/api/ace/campaigns/{id}', {
      params: {
        path: { id: campaignId }
      }
    });
  },
  updateCampaignById: ({ campaignId, data }: {
    campaignId: string,
    data: components['schemas']['Campaign-ace_campaign.write']
  }) => {
    return client.PATCH('/api/ace/campaigns/{id}', {
      params: {
        path: { id: campaignId }
      },
      body: data
    });
  },
  deleteCampaignById: ({ campaignId }: { campaignId: string }) => {
    return client.DELETE('/api/ace/campaigns/{id}', {
      params: {
        path: { id: campaignId }
      }
    });
  }
});
