import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Outlet } from 'react-router-dom';
import UserProvider from '../../lib/userProvider';

import SideMenu from '../SideMenu/sideMenu';

import Login from '../Login/login';

import {
  MainContainer,
  RightContentWrapper
} from './app.styles';

const App = ()=> {
  const { isAuthenticated, isLoading } = useAuth0();

  if(!isAuthenticated) {
    return <Login />;
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <UserProvider>
      <MainContainer>
        <SideMenu/>
        <RightContentWrapper>
          <Outlet />
        </RightContentWrapper>
      </MainContainer>
    </UserProvider>
  );
};

export default App;
