import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { userContext, User } from '../context/userContext';

const UserProvider = ({ children } : { children: React.ReactNode}):React.ReactElement => {
  const { user } = useAuth0();

  return (
    <userContext.Provider value={{ user: user as User }}>
      {children}
    </userContext.Provider>
  );

};

export default UserProvider;
