import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { components } from 'src/lib/api/openapi';
import { Button } from '@arcade/component-library';

import { apiClient } from '../../lib/apiConfig';
import { userContext } from '../../context/userContext';
import CampaignForm, { type CampaignFormType, type Site } from '../../components/CampaignForm/campaignForm';

import { padNumber } from '../../lib/utils';

import PageWrapper from '../../components/PageWrapper/pageWrapper';
import PreviewPanel from '../../components/PreviewPanel/previewPanel';

type Campaign = components['schemas']['Campaign-ace_campaign.read'];

type Target = components['schemas']['Targeting-ace_action.read'];

import {
  Wrapper,
  LeftColumn,
  FormWrapper,
  ErrorMessage,
  ActionsWrapper,
  PreviewWrapper
} from './newCampaign.styles';

const NewCampaign = ():React.ReactElement => {
  const [sites, setSites] = useState<Site[]>([]);
  const [channels, setChannels] = useState<Site[]>([]);
  const [campaignToEdit, setCampaignToEdit] = useState<Campaign>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [iFrameKey, setiFrameKey] = useState<string>(Math.random().toString());

  const [searchParams] = useSearchParams();
  const { user } = useContext(userContext);

  useEffect(() => {
    const getSetting = async () => {
      const { data } = await apiClient.settingsApi.getSetting();
      if (data) {
        let allChannels:Site[] = [];
        const allSites:Site[] = [];
        data.forEach(site => {
          allSites.push({ code: site.code, name: site.name });
          if (site.channels) {
            const channels = site?.channels.map(channel => ({ code: channel.id, name: channel.title }));
            allChannels = [...allChannels, ...channels];
          }
        });
        if (allChannels.length > 0) {
          const jsonObj = allChannels.map(channel => JSON.stringify(channel));
          const unique = new Set(jsonObj);
          allChannels = Array.from(unique).map(item => JSON.parse(item));
          setChannels(allChannels);
        }
        setSites(allSites);
      }
    };
    getSetting();
    const getCampaign = async (id:string) => {
      const { data, error } = await apiClient.campaignsApi.getCampaignById({ campaignId: id });
      if (data) {
        setCampaignToEdit(data);
        setLoaded(true);
      }
      if(error) console.error(error);
    };
    const urlCampaignId = searchParams.get('campaignId');
    if (urlCampaignId) {
      getCampaign(urlCampaignId);
    } else {
      setLoaded(true);
    }
  }, []);

  const getObjectFromArray = (arrayToConvert?: string[]) => {
    if(!arrayToConvert || arrayToConvert === undefined) return [];
    const obj:{ [key:string]: boolean} = {};
    for (let i = 0; i < arrayToConvert.length; i++) {
      obj[arrayToConvert[i]] = true;
    }
    return obj;
  };

  const getarrayFromObject = (obj?: { [key:string]: boolean}):string[] => {
    const arr:string[] = [];
    if(!obj) return arr;
    if (obj.all) {
      arr.push('all');
    } else {
      for (const [key, value] of Object.entries(obj)) {
        if (value) arr.push(key);
      }
    }
    return arr;
  };

  const parseDate = (UTCDate:string):string => {
    const utc = new Date(UTCDate);
    return `${utc.getFullYear()}-${padNumber(utc.getMonth() + 1)}-${padNumber(utc.getDate())}T${padNumber(utc.getHours())}:${padNumber(utc.getMinutes())}`;
  };

  const getTargetingValue = (target:Target):{targetingKey: string | undefined, targetingValue:string | undefined } => {
    const targetingObj:{targetingKey: string | undefined, targetingValue:string | undefined } = {
      targetingKey: undefined,
      targetingValue: undefined
    };
    for ( const [key, value] of Object.entries(target)) {
      if (value) {
        targetingObj.targetingKey = key;
        targetingObj.targetingValue = value;
      }
    }
    return targetingObj;
  };

  const getFormData = (apiData:Campaign):CampaignFormType | undefined => {
    if(!apiData) return undefined;
    return {
      ...apiData,
      ...{
        channels: getObjectFromArray(apiData?.channels),
        sites: getObjectFromArray(apiData?.sites),
        startDate: apiData?.startDate ? parseDate(apiData?.startDate) : undefined,
        endDate: apiData.endDate ? parseDate(apiData.endDate) : undefined,
        actions: apiData?.actions && apiData?.actions.map(action => ({ ...action, ...getTargetingValue(action.targeting as Target) }))
      }
    } as CampaignFormType;
  };

  const submitData = async (data:CampaignFormType) => {
    const sitesArray = getarrayFromObject(data.sites);
    const channelArray = getarrayFromObject(data.channels);
    const UTCStartDate = data.startDate ? new Date(data.startDate).toUTCString() : undefined;
    const UTCEndDate = data.endDate ? new Date(data.endDate).toUTCString() : undefined;
    const actions = data.actions.map(action => {
      const targeting:{[key:string]: string} = {};
      targeting[action.targetingKey] = action.targetingValue;
      return ({ ...action, ...{ targeting } });
    });
    const formattedData = Object.assign(
      {},
      data,
      { sites: sitesArray, channels: channelArray, startDate: UTCStartDate, endDate: UTCEndDate });
    if (campaignToEdit) {
      const { data : campaignData, error } = await apiClient.campaignsApi.updateCampaignById({
        campaignId: campaignToEdit.id as string,
        data: { ...formattedData, ...{ editedBy: user?.nickname }, ...{ actions } }
      });
      if (campaignData) {
        setCampaignToEdit(campaignData);
      }
      if (error) setSubmitError('There was a problem updating the campaign.');
    } else {
      const { data : campaignData, error } = await apiClient.campaignsApi.addCampaigns({ campaign: { ...formattedData, ...{ createdBy: user?.nickname ?? '', status: 'draft' }, ...{ actions } } });
      if (campaignData) {
        setCampaignToEdit(campaignData);
      }
      if (error) setSubmitError('There was a problem saving the campaign');
    }
    setiFrameKey(Math.random().toString());
  };

  const getCampaignSites = (sites:Site[]):Site[] => {
    if(!campaignToEdit) return sites;
    const campaignSites = campaignToEdit.sites;
    if(campaignSites?.includes('all')) return sites;
    const filteredSites = [];
    for (let i = 0; i < sites.length; i++) {
      if (campaignSites?.includes(sites[i].code as string)) filteredSites.push(sites[i]);
    }
    return filteredSites;
  };

  const handlePublish = async () => {
    const newStatus = campaignToEdit?.status === 'draft' ? 'published' : 'draft';
    const { data, error } = await apiClient.campaignsApi.updateCampaignById({
      campaignId: campaignToEdit?.id as string,
      data: {
        status: newStatus,
        editedBy: user?.nickname as string
      } as Campaign
    });
    if (data) {
      setCampaignToEdit(data);
    }
    if (error) console.error(error);
  };

  return (
    <PageWrapper title={`Campaign${ campaignToEdit ? ` - ${campaignToEdit.status}` : ''}`}>
      <Wrapper>
        <LeftColumn>
          <FormWrapper>
            {loaded ? <CampaignForm
              formId='campaign-form'
              campaign={getFormData(campaignToEdit as Campaign)}
              sites={sites}
              channels={channels}
              onSubmit={submitData}
            /> : <p>loading...</p>}
          </FormWrapper>
          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          <ActionsWrapper>
            <Button type='submit' form='campaign-form' btnType='outlined'>Save</Button>
            <Button onClick={handlePublish} disabled={!campaignToEdit?.id}>{campaignToEdit?.status === 'draft' ? 'Publish campaign' : 'Unpublish campaign'}</Button>
          </ActionsWrapper>
        </LeftColumn>
        <PreviewWrapper>
          <PreviewPanel iFrameKey={iFrameKey} sites={getCampaignSites(sites)} campaignId={campaignToEdit?.id} disabled={!campaignToEdit?.id} />
        </PreviewWrapper>
      </Wrapper>
    </PageWrapper>
  );
};

export default NewCampaign;
