import React, { useState, useEffect } from 'react';
import PageWrapper from '../../components/PageWrapper/pageWrapper';
import Table from '../../components/Table/table';

import { components } from 'src/lib/api/openapi';
import { apiClient } from 'src/lib/apiConfig';

type Campaign = components['schemas']['Campaign-ace_campaign.read'];

import {
  DashboardWrapper
} from './dashboard.styles';

const Dashboard = ():React.ReactElement => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    const getCampaigns = async () => {
      const { data, error } = await apiClient.campaignsApi.getCampaigns();
      if (data) setCampaigns(data);
      if (error) console.error(error);
    };

    getCampaigns();
  }, []);

  return (
    <PageWrapper title='Dashboard'>
      <DashboardWrapper>
        <Table campaigns={campaigns} />
      </DashboardWrapper>
    </PageWrapper>
  );
};

export default Dashboard;
