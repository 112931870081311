import React from 'react';

import { Title } from '@arcade/component-library';

import UserBadge from '../../components/UserBadge/userBadge';

import {
  Wrapper,
  Header
} from './pageWrapper.styles';

const PageWrapper = ({ children, title }: { children: React.ReactNode, title: string}):React.ReactElement => {
  return (
    <Wrapper>
      <Header>
        <Title tag='h1' extraClass='h2' text={title} />
        <UserBadge />
      </Header>
      {children}
    </Wrapper>
  );
};

export default PageWrapper;
