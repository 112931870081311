import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { components } from 'src/lib/api/openapi';
import { Title, Button, Icon } from '@arcade/component-library';

import { formatDateTime } from 'src/lib/utils';

import { apiClient } from 'src/lib/apiConfig';
import { userContext } from 'src/context/userContext';

import PageWrapper from '../../components/PageWrapper/pageWrapper';

type Campaign = components['schemas']['Campaign-ace_campaign.read'];

import {
  Wrapper,
  ContentWrapper,
  DetailsWrapper,
  ActionsWrapper
} from './editCampaign.styles';

const EditCampaign = ():React.ReactElement => {
  const [campaign, setCampaign] = useState<Campaign>();
  const params = useParams();
  const campaignId = params.campaignId;

  const { user } = useContext(userContext);
  const navigate = useNavigate();

  useEffect(() => {
    const retriveCampaign = async (id:string) => {
      const { data, error } = await apiClient.campaignsApi.getCampaignById({ campaignId: id });
      if (data) setCampaign(data);
      if (error) console.error(error);
    };

    if (campaignId) retriveCampaign(campaignId);
  }, [campaignId]);

  const handleEdit = () => {
    navigate(`/campaign/new?campaignId=${campaignId}`);
  };

  const handleDelete = async () => {
    const { data, error } = await apiClient.campaignsApi.deleteCampaignById({ campaignId: campaignId as string });
    if (data) {
      setCampaign(undefined);
    }
    if (error) console.error(error);
  };

  const handleChangeStatus = async () => {
    const newStatus = campaign?.status === 'draft' ? 'published' : 'draft';
    const { data, error } = await apiClient.campaignsApi.updateCampaignById({
      campaignId: campaignId as string,
      data: {
        status: newStatus,
        editedBy: user?.nickname as string
      }
    });
    if (data) {
      setCampaign(data);
    }
    if (error) console.error(error);
  };

  return (
    <PageWrapper title={campaign?.name || ''}>
      {campaign ? (
        <Wrapper>
          <ContentWrapper>
            <DetailsWrapper>
              <Title text='Campaign Details' tag='h2' extraClass='h3' />
              <br/>
              <p><b>Campaign id:</b> {campaignId}</p>
              <p><b>Campaign name:</b> {campaign?.name}</p>
              <p><b>Campaign Description:</b> {campaign?.description}</p>
              <p><b>Created by:</b> {campaign?.createdBy}</p>
              <p><b>Last edited by:</b> {campaign?.editedBy}</p>
              <p><b>Campaign type:</b> {campaign?.type}</p>
              <p><b>Campaign status:</b> {campaign?.status}</p>
              <p><b>Start date:</b> {campaign?.startDate ? formatDateTime(campaign?.startDate) : 'n/a'}</p>
              <p><b>Finish date:</b> {campaign?.endDate ? formatDateTime(campaign?.endDate) : 'n/a'}</p>
              <p><b>Targeting channels:</b> {campaign?.channels ? campaign?.channels.join(', ') : 'n/a'}</p>
              <p><b>Targeting sites:</b> {campaign?.sites ? campaign?.sites.join(', ') : 'n/a'}</p>
            </DetailsWrapper>
            <DetailsWrapper>
              <Title text='Actions' tag='h2' extraClass='h3' />
              <br/>
              {campaign?.actions && campaign.actions?.map(action => (
                <div key={action.id}>
                  <p><b>Action type:</b> {action.type}</p>
                  <p><b>Action mode:</b> {action.mode}</p>
                  {/* {action?.position && <p><b>Position:</b> {action.position}</p>}
                  {action?.content && <p><b>Position:</b> {action.content}</p>} */}
                  <br/>
                </div>
              ))}
            </DetailsWrapper>
          </ContentWrapper>
          <ActionsWrapper>
            <div>
              <Button btnType='outlined' onClick={handleEdit}><Icon glyph='pencil_fill' /> Edit Campaign</Button>
              <Button btnType='text' onClick={handleDelete}><Icon glyph='close'/> Delete Campaign</Button>
            </div>
            <Button onClick={handleChangeStatus}><Icon glyph='check_line'/>{campaign?.status === 'draft' ? 'Publish' : 'Unpublish'}</Button>
          </ActionsWrapper>
        </Wrapper>
      ) : <p>Campaign not found</p>}
    </PageWrapper>
  );
};

export default EditCampaign;
