import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from 'styled-components';

import App from './components/App/app';
import Dashboard from './pages/Dashboaard/dashboard';
import NewCampaign from './pages/NewCampaign/newCampaign';
import EditCampaign from './pages/EditCampaign/editCampaign';
import MyCampaigns from './pages/MyCampaigns/myCampaigns';
import Profile from './pages/Profile/profile';

import { theme, GlobalStyle } from '@arcade/component-library/';
import { layout } from './components/styles/layout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        path: 'actions',
        element: <p>My Action</p>
      },
      {
        path: 'campaign/new',
        element: <NewCampaign />
      },
      {
        path: 'campaign/:campaignId',
        element: <EditCampaign />
      },
      {
        path: 'campaign/user/:userid',
        element: <MyCampaigns />
      },
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  }
]);

const aceTheme = Object.assign({}, theme, { layout });

const container = document.getElementById('app');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENTID}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}>
        <ThemeProvider theme={aceTheme}>
          <GlobalStyle />
          <RouterProvider router={router} />
        </ThemeProvider>
      </Auth0Provider>
    </React.StrictMode>);
}
