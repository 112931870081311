import { styled } from 'styled-components';

export const InlineFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    flex-direction: row;
  }
`;

export const ActionWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const ActionBtn = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    margin-right: 5px;
  }
`;

export const FormActionsWrapper = styled.div`
  margin-top: 30px;

  hr {
    margin-bottom: 15px;
    border: 1px solid ${props => props.theme.colors.lightGrey};
  }

  button svg {
    margin-right: 10px;
  }
`;
