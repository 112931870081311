import React from 'react';

import { Controller, type Control } from 'react-hook-form';

import { FormField, TextBox, DropDown } from '@arcade/component-library';
import type { CampaignFormType } from '../CampaignForm/campaignForm';

export type BaseActionFields = {
  mode?: string
  targetingKey: string
  targetingValue: string
  type: string
}

const targetingKeys = [
  {
    value: 'path',
    label: 'Path'
  },
  {
    value: 'tagName',
    label: 'Tag Name'
  },
  {
    value: 'tagValue',
    label: 'Tag Value'
  }
];

import {
  InlineFields
} from './actionFields.styles';

const BaseAction = ({ control, index, errors } : {
  control: Control<CampaignFormType>,
  index: number,
  errors: any
 }):React.ReactElement => {

  return(
    <div>
      <Controller
        name={`actions.${index}.type`}
        control={control}
        render={({ field }) => (
          <input {...field} type='hidden' />
        )}
      />
      <Controller
        name={`actions.${index}.mode`}
        control={control}
        render={({ field }) => (
          <FormField
            id={`action-${index}-mode`}
            label='Mode'
          >
            <TextBox {...field} id={`action-${index}-mode`} />
          </FormField>
        )}
      />
      <InlineFields>
        <Controller
          name={`actions.${index}.targetingKey`}
          control={control}
          rules={{
            required: 'Please add a targeting method'
          }}
          render={({ field }) => (
            <FormField
              id={`action-${index}-targeting-key`}
              label='Targeting Mode'
              errorLabel={errors?.actions?.index?.targetingKey?.message || ''}
              required
              half
            >
              <DropDown
                {...field}
                testid='a_targeting-key'
                id={`action-${index}-targeting-key`}
                options={targetingKeys}
                placeholder='Please select'
                error={!!errors?.actions?.index?.targetingKey || false}
              />
            </FormField>
          )}
        />
        <Controller
          name={`actions.${index}.targetingValue`}
          control={control}
          rules={{
            required: 'Please enter a targeting value'
          }}
          render={({ field }) => (
            <FormField
              id={`action-${index}-targeting-value`}
              label='Targeting Value'
              errorLabel={errors?.actions?.index?.targetingValue?.message || ''}
              required
              half
            >
              <TextBox {...field} id={`action-${index}-targeting-value`} state={errors?.actions?.index?.targetingValue ? 'error' : 'normal'} />
            </FormField>
          )}
        />
      </InlineFields>
    </div>
  );
};

export default BaseAction;
